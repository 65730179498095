import React, { Component } from 'react'
import zip from 'lodash/zip'
import { Container, Row, Col } from 'reactstrap'
import styled from 'styled-components'
import sluggify from '../sluggify'

const CustomerCard = styled(Row)`
  background: var(--white);
  border-bottom: 1px solid #ddd;
`

export class Customers extends Component {
  render() {
    const { edges } = this.props.data
    const customers = zip(edges[0].node.customers, edges[1].node.customers)

    return (
      <div>
        <div className="bg-dark py-3 mt-3">
          {' '}
          <Container>
            <h2 className="text-primary text-center" id="our-customers">
              {edges[0].node.customersTitle}
            </h2>
          </Container>
        </div>
        <div className="pt-3 pb-5">
          <Container>
            {customers.map((item, i) => (
              <CustomerCard
                className="p-5  "
                id={sluggify(item[0].title)}
                key={i}
              >
                <Col md="3">
                  <img
                    alt={item[0].title}
                    src={item[0].file.url}
                    title={item[0].title}
                    className="img-fluid w-100 mb-0"
                  />
                  <h5 className="text-center py-3 text-gold">
                    {' '}
                    {item[0].title}
                  </h5>
                </Col>

                <Col
                  md="9"
                  className="d-flex flex-column justify-content-center"
                >
                  <p className="text-justify">{item[1].description}</p>
                  <h4 className="text-center text-primary">{item[1].title}</h4>
                </Col>
              </CustomerCard>
            ))}
          </Container>
        </div>
      </div>
    )
  }
}
