import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Hero from '../components/customersPage/Hero'
import { Customers as CustomersList } from '../components/customersPage/Customers'
import Logos from '../components/customersPage/Logos'

import Seo from '../components/shared/Seo'
import Icons from '../components/shared/Icons'

class CustomersPage extends React.Component {
  render() {
    return (
      <Layout>
        <Seo {...this.props.data.seo} />
        <Hero data={this.props.data.data.edges[0]} />
        <CustomersList data={this.props.data.data} />
        <Logos data={this.props.data.data.edges[0]} />
        <Icons />
      </Layout>
    )
  }
}

export default CustomersPage

export const query = graphql`
  {
    seo: contentfulCustomersPage {
      title: pageName
      description
      keywords
    }

    data: allContentfulCustomersPage {
      edges {
        node {
          id
          title
          subtitle {
            g: childMarkdownRemark {
              html
            }
          }
          image {
            title
            description
            file {
              url
            }
          }
          customersTitle
          customers {
            id
            title
            description
            file {
              url
            }
          }
          logos {
            title
            description
            file {
              url
            }
          }
          node_locale
        }
      }
    }
  }
`
