import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'

export default class Logos extends Component {
  render() {
    const {
      node: { logos },
    } = this.props.data

    return (
      <Container className="pb-5">
        <Row>
          {logos.map((item, i) => (
            <Col md="3" key={i} className="pb-5">
              <div
                className="w-100"
                title={item.title}
                style={{
                  paddingBottom: '90%',
                  margin: '0 auto 0em auto',
                  background: `url(${item.file.url})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'bottom center',
                  backgroundRepeat: 'no-repeat',
                }}
              />
              <div
                style={{ fontWeight: '900' }}
                className="text-bold py-3 px-5 text-center  text-gold  "
              >
                {item.title}
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    )
  }
}
