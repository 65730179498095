import React, { Component } from 'react'
import { navigate } from 'gatsby'
import { Container, Row, Col, Button } from 'reactstrap'
import styled from 'styled-components'
import Legal from '../shared/Legal'

const HTML = styled.div`
  p {
    margin-bottom: 0em;
    font-size: 1.5em;
  }
`

export default class Hero extends Component {
  render() {
    const {
      node: { title, subtitle, image },
    } = this.props.data
    return (
      <Container className="pt-5">
        <Row>
          <Col md="12" className="mb-4">
            {' '}
          </Col>
          <Col md="6" className="d-flex flex-column justify-content-between">
            <h1 className="mb-3    ">{title}</h1>
            <HTML
              className="text-lg-left  mb-4 text-center"
              dangerouslySetInnerHTML={{ __html: subtitle.g.html }}
            />
            <Row>
              <Col sm={{ size: 8, offset: 2 }} lg={{ offset: 0, size: 6 }}>
                <Button
                  style={{ height: '60px', lineHeight: 1 }}
                  size="lg"
                  onClick={e => {
                    e.preventDefault()
                    navigate('/contact', {
                      state: {
                        selected: 'Contact Us',
                      },
                    })
                  }}
                  className="w-100 text-white py-3  text-center primary-btn-gradient  "
                >
                  {' '}
                  Get Started
                </Button>
                <Legal className="text-center" />
              </Col>
            </Row>
          </Col>

          <Col md="6">
            <img
              alt={image && image.description}
              src={image && image.file.url}
              title={image && image.title}
              className="img-fluid mb-3 d-block mx-auto border-40 "
            />
          </Col>
        </Row>
      </Container>
    )
  }
}
